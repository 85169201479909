/* footer, footer>* {
    border: thin solid rgb(255, 255, 255);
} */


/* footer {
    width: 100%;
    height: 500px;
    background: #0e112a;
} */

/* footer .container {
    max-width: 1200px;
    margin: 0 auto;
    height: 100%;
} */

.footer {
    background-color: #2c3e50;
    color: #ecf0f1;
    padding: 20px 0;
    text-align: center;
}

.footer-top {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
    padding: 20px;
    flex-wrap: wrap;
}

.footer-section {
    padding: 10px;
    width: 300px;
}

.footer-section h4 {
    margin-bottom: 15px;
    font-size: 1.2em;
}

.footer-section p,
.footer-section ul {
    margin: 5px 0;
    font-size: 0.9em;
}

.footer-section ul {
    list-style: none;
    padding: 0;
}

.footer-section ul li {
    margin-bottom: 10px;
}

.footer-section ul li a {
    color: #ecf0f1;
    text-decoration: none;
}

.footer-section ul li a:hover {
    text-decoration: underline;
}

.social-icons a {
    color: #ecf0f1;
    margin: 0 10px;
    font-size: 1.5em;
    transition: color 0.3s;
}

.social-icons a:hover {
    color: #3498db;
}

.footer-bottom {
    background-color: #1a252f;
    padding: 15px;
    font-size: 0.8em;
}

.footer-bottom p {
    margin: 5px 0;
}

.footer-bottom a {
    color: #ecf0f1;
    text-decoration: none;
}

.footer-bottom a:hover {
    text-decoration: underline;
}