.featured,
.featured * {
    /* border: thin solid black; */
}


.featured .frontend {
    max-width: 900px;
    border-radius: 1cm;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1cm;

}

.featured .img-area {
    height: 6cm;
    max-width: 9.66cm;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    /* transform: scale(1.5); */
}

.frontend img {
    width: 100%;
}

.featured .tag-area {
    display: flex;
}

.featured .txt-area {
    /* height: 6cm; */
    /* width: 11.375cm; */
    border-radius: 10px;
    padding: 5px;
}

.featured h1,
.featured h4 {
    padding: 5px;
}

.featured ul {
    list-style-position: inside;
    padding: 5px;
}

.featured li {
    padding: 5px;
}

.featured .foot {
    display: flex;
    justify-content: space-between;
    padding: 5px;
}


@media (max-width: 800px) {
    .featured .frontend {
        flex-direction: column;
    }
}