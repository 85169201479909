* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: segoe-vf, sans-serif;
  /* border: thin solid rgba(255, 255, 255, 0.05); */
  color: white;
}


/* here I write page layout */
.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

main {
  flex-grow: 1;
}

section {
  padding: 10px;
}

section>.container {
  margin: auto;
  max-width: 1200px;
  padding: 50px 12px;
  overflow: hidden;
}
section>.container>.title {
  margin-bottom: 100px;
  text-align: center;
  font-variant-caps: all-petite-caps;
  font-size: 3em;
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: rgba(65, 61, 61, 0.5);
  backdrop-filter: blur(10px);
  color: white;
  border: thin solid rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  /* background-color: #28a745; */
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  border: thin solid white;
}
.btn:hover{
  background-color: rgba(0, 0, 0, 0.4);
}

.tag {
  background-color: #dcdcdc;
  color: rgb(0, 0, 0);
  padding: 2px 5px;
  border-radius: 5px;
  margin: 5px;
  font-size: 12px;
  margin: 5px;
}

.glass {
  background: rgba(255, 255, 255, 0.1);
  border-bottom: thin solid rgba(255, 255, 255, 0.5);
  border-left: thin solid rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
  box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.3);
  /* box-shadow: -5px -5px 10px rgba(255, 255, 255, 0.1); */
}

